import React from "react";
import { useNavigate } from "react-router-dom";
function Brand({ homePageData }) {
  const navigate = useNavigate()
  const Brand_img1 = homePageData.acf.brand_img1_;
  const Brand_img2 = homePageData.acf.brand_img2;
  const Brand_img3 = homePageData.acf.brand_imag3;
  let brandImgs = homePageData.acf.services_photos;
console.log(brandImgs);
  const serviceClickHanlder = () => {
    navigate("/service")
  };
  return (
    <section className="brand-section">
      <div className="container">
        <div className="brand-main">
          <div className="brand-text" dangerouslySetInnerHTML={{ __html: homePageData.acf.brand_top_section}} />
          <div className="brand-images">
            {brandImgs.map((img, i) => (
                 <div className="brand-image" key={i}>
                 <img srcSet={img.upload_service_images || undefined} alt="Brand Image" />
                 <h3 dangerouslySetInnerHTML={{__html: img.image_brief}} />
               </div>
            ))}
          </div>
        

          <div className="brand-footer">
            <button className="white-button brand-section-button" onClick={serviceClickHanlder}>
              {homePageData.acf.brand_button_section}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Brand;
