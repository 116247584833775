import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useContextSnippet } from '../context/ContextApi';
import axios from "axios";

function Review() {
    const apiUrl = useContextSnippet();
    const [carouselData, setCarouselData] = useState([]);
    const [error, setError] = useState(null);
    const sliderRef = useRef(null); // Create a ref for the Slider component

    useEffect(() => {
        const fetchCarouselData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/reviews?_embed&per_page=50&orderby=menu_order`);
                setCarouselData(response.data);
            } catch (error) {
                console.error('Error fetching carousel data:', error);
                setError('Failed to fetch carousel data. Please try again later.');
            }
        };
        fetchCarouselData();
    }, [apiUrl]);

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow prev-arrow" onClick={onClick}>
            <span className="arrow-icon">←</span>
        </div>
    );

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow next-arrow" onClick={onClick}>
            <span className="arrow-icon">→</span>
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 1, 
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <div />, 
        nextArrow: <div />, 
        customPaging: i => (
            <div className="custom-dot" />
        ),
    };

    const truncateText = (text, wordLimit) => {
        if (!text) return "";
        const words = text.split(' ');
        if (words.length <= wordLimit) return text;
        return words.slice(0, wordLimit).join(' ') + '...';
    };

    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleReadMoreClick = (index) => {
        setExpandedIndex(prevIndex => (prevIndex === index ? null : index));
    };
    // const setEqualHeight = () => {
    //     let maxHeight = 0;
    //     const slides = document.querySelectorAll('.slick-slide');
  
    //     // Find the max height of all slides
    //     slides.forEach((slide) => {
    //       const slideHeight = slide.offsetHeight;
    //       if (slideHeight > maxHeight) {
    //         maxHeight = slideHeight + 200;
    //       }
    //     });
    //     console.log(maxHeight);
    //     // Apply the max height to all slides
    //     slides.forEach((slide) => {
    //       slide.style.height = `${maxHeight}px`;
    //     });
    //   };
  
    //   // Initial call to set equal height
    //   setEqualHeight();
  
    //   // Recalculate on window resize
    //   window.addEventListener('resize', setEqualHeight);
    return (
        <section className="crousel-study">
            <div className='container'>
                {error && <p>{error}</p>}
                <Slider ref={sliderRef} {...settings}>
                    {carouselData.length > 0 ? (
                        carouselData.map((item, index) => (
                            <div className='crousel-study-Rmain review-slider' key={index}>
                                <div className="crousel-study-textT">
                                    <div className="text-crousel" style={{margin: 'auto'}}>
                                        {item.content.rendered &&
                                            <>
                                                <div className="reviewText" dangerouslySetInnerHTML={{__html: item.content.rendered}} />
                                               {/* <p dangerouslySetInnerHTML={{ __html: expandedIndex === index ? item.content.rendered : truncateText(item.content.rendered, 60) }}></p>
                                                 {item.content.rendered.length > 60 && 
                                                    <em onClick={() => handleReadMoreClick(index)}>
                                                        {expandedIndex === index ? 'Read Less' : 'Read More'}
                                                    </em>
                                                } */}
                                            </>
                                        }
                                        {item.title.rendered &&
                                            <h3 style={{textAlign: 'center', margin: '2rem'}}>{item.title.rendered}</h3>
                                        }
                                    </div>
                                   {/* <div className="crousel-study-image">
                                        <div className="crousel-image-box">
                                         {item._embedded?.["wp:featuredmedia"]?.[0]?.source_url &&
                                            <img src={item._embedded?.["wp:featuredmedia"]?.[0]?.source_url} alt={item.acf.review_name || "Review image"} />
                                        } 
                                        {item.title.rendered &&
                                            <h3>{item.title.rendered}</h3>
                                        }
                                        {item.acf.review_title &&
                                            <p>{item.excerpt.rendered} Case Study</p>
                                        }
                                        </div>
                                    </div>*/}
                                    <div className="arrow-button" style={{justifyContent: 'center'}}>  
                                        <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
                                        <NextArrow onClick={() => sliderRef.current.slickNext()} />
                                    </div>
                                </div>
                                
                            </div>
                        ))
                    ) : (
                        <p>No reviews available</p>
                    )}
                </Slider>
            </div>
        </section>
    );
}

export default Review;
