import React from 'react';
import logo from "../assets/Stature 1 No Back.svg";

import { Link } from 'react-router-dom';

function Header() {
    return (
        <nav>
            <div className='container'>
                <div className='header-main'>
                    <div className='header-logo'>
                        <Link to='/'>
                            <img className="header-logo" src={logo} alt="Company Logo" />
                        </Link>
                    </div>
                    <div className='header-nav'>
                        <Link to='/service' className='header-home service'><span>Service</span></Link>
                        <Link to='/our-work' className='header-home work'><span>Work</span></Link>
                        <Link to='/about' className='header-home about-us'><span>About us</span> </Link>
                        <Link to='/contact' className='header-home  contact-us' ><span>Contact us</span></Link>

                    </div>
                </div>
            </div>
        </nav>
    );
}
export default Header;
